import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import error from '../components/404.vue'
import { MIRCO_APP } from '../const'
// 解决跳转到当前路由报错问题

const routes: Array<RouteRecordRaw> = [
  {
    path: '/404',
    name: '404',
    component: error
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/blogs/list.vue')
  },
  {
    path: '/blogs/detail',
    name: 'blogDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/blogs/detail.vue')
  },
  {
    path: '/blogs/edit',
    name: 'blogEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/blogs/edit.vue')
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name) {
    // 有 name 属性，说明是主应用的路由
    next()
    return
  }
  const childrenPath = MIRCO_APP.filter((i: any) => i.isMicroApp).map((i: any) => i.router);
  if (childrenPath.some((item: any) => to.path.includes(item))) {
    next()
    return
  }
  next({ name: '404' })
})
router.afterEach((to, from, failure) => {
})

export default router
