import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/tailwind.css'
import { ObjectType, registerMicroApps, RegistrableApp, start, addGlobalUncaughtErrorHandler } from 'qiankun'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';

import VMdEditor from '@kangc/v-md-editor';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import hljs from 'highlight.js';
import '@kangc/v-md-editor/lib/style/preview.css';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import '@kangc/v-md-editor/lib/style/base-editor.css';

import { MIRCO_APP } from './const'

VMdEditor.use(githubTheme, {
  Hljs: hljs,
})

document.title = 'Saltfisher'
let app = createApp(App)
const microApp = MIRCO_APP
app.config.globalProperties.$microApp = microApp
let apps: any = [];
microApp.forEach(app => {
  if (!app.isMicroApp) {
    return
  }
  const { name, router, url } = app
  apps.push(
    {
      name: name,
      entry: url,
      //fetch,
      container: '#vue',
      activeRule: router,
      props: {
        test_param: {
          userid: 111,
          corpid: 222
        },
        app,
        store
      }
    }
  );
})
const config: any = {
  beforeLoad: [
    (app: any) => {
      console.log("%c before load",
        'background:#0f0 ; padding: 1px; border-radius: 3px;  color: #fff',
        app);
    }
  ], // 挂载前回调
  beforeMount: [
    (app: any) => {
      console.log("%c before mount",
        'background:#f1f ; padding: 1px; border-radius: 3px;  color: #fff',
        app);
    }
  ], // 挂载后回调
  afterUnmount: [
    (app: any) => {
      app.props.store.unregisterModule('mircoStore')
      console.log("%c after unload",
        'background:#a7a ; padding: 1px; border-radius: 3px;  color: #fff',
        app);
    }
  ] // 卸载后回调
}
registerMicroApps(apps, config);
let option = { prefetch: false }
start({ sandbox: true });
addGlobalUncaughtErrorHandler((event: any) => {
  if (event.error && event.type === 'error') {
    const pathname = event.path[0].location.pathname
    const appOrParcelName = event.error.appOrParcelName
    const path: any = microApp.filter((i: any) => i.isMicroApp && i.name === appOrParcelName)[0]?.router
    path === pathname && router.replace('/404')
  }
});
app
  .use(store)
  .use(router)
  .use(ArcoVue)
  .use(VueAxios, axios)
  .use(VMdEditor)
  .mount('#mainApp')
