import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    shareInfo: '测试',
    menuList: [],
    userInfo: {}
  },
  getters: {
    shareInfo(state) { return state.shareInfo },
    menuList(state) { return state.menuList },
  },
  mutations: {
    ['SHARE_INFO'](state, shareInfo) {
      state.shareInfo = shareInfo + parseInt(Math.random() * 10 as any)
    },
    ['MENU_LIST'](state, menuList) {
      state.menuList = menuList
    },
    ['USER_INFO'](state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    setShareInfo({ commit, state }, shareInfo) {
      commit('SHARE_INFO', shareInfo)
    },
    setMenuList({ commit, state }, menuList) {
      commit('MENU_LIST', menuList)
    },
    setUserInfo({ commit, state }, userInfo) {
      commit('USER_INFO', userInfo)
    }
  },
  modules: {
  },
  plugins: [
    // 持久化
    createPersistedState({
      reducer(val) {
        return {
          userInfo: val.userInfo
        }
      }
    })
  ]
})
